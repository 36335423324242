<template>
  <div>
    <!-- start of Summary modal -->
    <b-modal id="suggestion-summary" size="lg" centered hide-footer hide-header>
      <div class="row mb-3">
        <div class="col align-content-center">
          <h4 class="mb-0"><b>{{ "Summary" }}</b></h4>
        </div>
        <div class="col">
          <button
            class="btn-primary btn px-2 float-right"
            :disabled="summaryLoading"
            @click="generateNewSummary"
          >
            {{ "Generate New Summary" }}
          </button>
        </div>
      </div>
      <div style="max-height: 70vh" class="card p-2 overflow-auto">
        <PageLoader
          v-if="summaryLoading"
          :loading="summaryLoading"
          class="w-100"
        ></PageLoader>

        <p v-else v-html="summary"></p>
      </div>
    </b-modal>
    <!-- end of Summary modal -->

    <PageLoader
      v-if="editorLoading || duration < 100"
      :loading="editorLoading"
      :duration="duration"
      message="generating"
    ></PageLoader>

    <div v-else style="margin: 2rem;">
      <div class="card shadow-sm">
        <button
          class="info-header w-100 p-3 d-flex justify-content-between"
          v-b-toggle.info-collapse
        >
          <h4 class="mb-0">
            <b>{{
              compose_type === "custom"
                ? "Custom Agreement Prompt"
                : "Letter Details"
            }}</b>
          </h4>
          <span class="material-icons-outlined arrow-icon">
            expand_less
          </span>
        </button>
        <b-collapse id="info-collapse">
          <div
            class="row px-3 pb-3"
            v-if="compose_type === 'custom' && content.prompt"
          >
            <div class="col">
              {{ content.prompt }}
            </div>
          </div>

          <div
            v-else-if="compose_type !== 'custom'"
            class="row px-3 pb-3"
          >
            <div class="col-4">
              <b>Jurisdiction</b>
              <p>{{ content.jurisdiction }}</p>
            </div>
            <div class="col-4">
              <b>Lawyer</b>
              <p>{{ content.lawyer }}</p>
            </div>
            <div class="col-4">
              <b>{{
                [
                  "Outstanding Payment",
                  "Property Damage Claim",
                  "Insurance Claim",
                ].includes($route.query.category)
                  ? "Incident Date"
                  : "Agreement Date"
              }}</b>
              <p>{{ content.date }}</p>
            </div>

            <div class="col-6 col-xl-3">
              <b>Sender Name</b>
              <p>{{ [content.sender_title, content.sender_name].join(" ") }}</p>
            </div>
            <div class="col-6 col-xl-3">
              <b>Sender Address</b>
              <p>{{ content.sender_address }}</p>
            </div>
            <div class="col-6 col-xl-3">
              <b>Receiver Name</b>
              <p>{{ [content.receiver_title, content.receiver_name].join(" ") }}</p>
            </div>
            <div class="col-6 col-xl-3">
              <b>Receiver Address</b>
              <p>{{ content.receiver_address }}</p>
            </div>

            <div
              class="col-6 col-xl-3"
              v-for="(val, key) in letter_details"
              :key="key"
              v-show="val && key"
            >
              <b>{{ key }}</b>
              <p>{{ val }}</p>
            </div>
          </div>
        </b-collapse>
      </div>

      <div class="row">
        <!-- start of Editor section -->
        <div class="col">
          <div
            style="height: calc(100% - 1.5rem);"
            class="card shadow-sm p-3 mt-4"
          >
            <div class="row mb-3">
              <div class="col align-content-center">
                <h4 class="mb-0 text-nowrap">
                  <b>{{ "Generated Content" }}</b>
                </h4>
              </div>
              <div class="col align-content-center" v-if="summary">
                <button
                  v-if="!summaryLoading"
                  @click="openSummary()"
                  class="btn btn-primary-outlined h-100 float-right"
                >
                  {{ "View Summary" }}
                </button>
                <button
                  v-if="summaryLoading"
                  @click="$toast.warning('Still in progress.')"
                  class="btn btn-warning-outlined h-100 float-right"
                  id="generateSummaryButton"
                >
                  {{ "Regenerating Summary" }}
                  <b-spinner label="Spinning" class="spinner" />
                </button>
              </div>
              <div
                class="col"
                v-else-if="
                  permissions.includes('nexlaw.letter-of-demand-summarize')
                "
              >
                <button
                  v-if="!summaryLoading"
                  @click="generateNewSummary"
                  class="btn-primary-outlined btn h-100 float-right"
                  id="generateSummaryButton"
                >
                  {{ "Generate Summary" }}
                </button>
                <button
                  v-if="summaryLoading"
                  @click="$toast.warning('Still in progress.')"
                  class="btn btn-warning-outlined h-100 float-right"
                  id="generateSummaryButton"
                >
                  {{ "Generating Summary" }}
                  <b-spinner label="Spinning" class="spinner"></b-spinner>
                </button>
              </div>
            </div>

            <QuillEditor
              disableCard
              :loading="editorLoading"
              :htmlContent="letter"
              disableImage
              @init-editor="initEditor"
              @update-editor="updateEditor"
              @save-changes="saveChanges"
            ></QuillEditor>
          </div>
        </div>
        <!-- end of Editor section -->

        <!-- start of Lawbot  -->
        <div class="col-12 col-lg-5" v-if="permissions.includes('nexlaw.qbot')">
          <div
            style="height: calc(100% - 1.5rem);"
            class="card shadow-sm p-3 mt-4 lawbot-card"
          >
            <h4
              class="pt-1 py-3"
              style="border-bottom: 1px solid lightgray"
            >
              <b>LawBot</b>
            </h4>
            
            <div class="card border-0" style="height: 663px">
              <div class="chat__box" id="messages" ref="messages1">
                <!-- Incoming Chat box -->
                <div
                  v-for="(message, idx) in chatMessages"
                  :key="idx"
                  :class="message.class_1"
                >
                  <div :class="message.class_1">
                    <div :class="message.class_3">
                      <!-- <vue-typer
                        @vueTyper:done="scrollToBottom"
                        :repeat="0"
                        v-if="message.new"
                        :text="message.text"
                      ></vue-typer> -->

                      <span
                        v-if="message.new"
                        v-html="
                          message.text
                            .replace(/\\n*/g, '<br>')

                            .replace(/\\n\\n*/g, '<br>')
                            .replace(/\\n\\n/g, '<br>')
                            .replace(/\\n/g, '<br>')
                            .replace(/\n/g, '<br>')
                            .replace(/\n\n*/g, '<br>')
                            .replace('*', '')
                            .replace(/\\/g, '<br>')
                        "
                      ></span>

                      <span
                        v-else
                        v-html="
                          message.text
                            .replace(/\\n*/g, '<br>')

                            .replace(/\\n\\n*/g, '<br>')
                            .replace(/\\n\\n/g, '<br>')
                            .replace(/\\n/g, '<br>')
                            .replace(/\n/g, '<br>')
                            .replace(/\n\n*/g, '<br>')
                            .replace('*', '')
                            .replace(/\\/g, '<br>')
                        "
                      ></span>
                    </div>
                  </div>
                </div>
                <!-- Outgoing chat box -->

                <div v-if="loadAnswer" class="chat__incoming">
                  <div class="chat__bubble__wrapper-incoming">
                    <div
                      class="chat__bubble-incoming animated-background-incoming"
                    >
                      <div
                        style="transform: scale(0.7)"
                        class="circles-to-rhombuses-spinner"
                      >
                        <div class="circle"></div>
                        <div class="circle"></div>
                        <div class="circle"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <button
                class="chat__suggestion"
                v-for="(question, index) in default_questions.slice(0, 3)"
                :key="index"
                @click="chooseMessage(question, index)"
                :disabled="loadAnswer || question.displayed"
              >
                <span>{{ question.text }}</span>
              </button>

              <div class="chat__input">
                <input
                  :disabled="loadAnswer"
                  @keyup.enter="sendNewMessage"
                  type="text"
                  v-model="NewText"
                  placeholder="Ask Questions about the Case..."
                />
                <button
                  v-if="NewText?.length > 0"
                  @click="sendNewMessage"
                  :disabled="loadAnswer"
                  class="btn-send"
                >
                  <span class="material-icons-sharp">
                    arrow_circle_right
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import PageLoader from "../../components/PageLoader.vue";
import Auth from "@/store/Auth.js";
import LegalWriter from "@/store/LegalWriter.js";
import QuillEditor from "@/components/QuillEditor.vue";

export default {
  components: {
    PageLoader,
    QuillEditor,
  },

  data() {
    return {
      permissions: localStorage.permissions,
      editorLoading: true,
      summaryLoading: false,
      editor: null,
      letter: "",
      compose_type: "",
      content: [],
      suggestion: "",
      changes: "SPECIFY THE LAW",
      status: "not_accepted",
      summary: "",
      changedLetter: "",
      duration: -1,
      interval: null,

      letter_details: {},

      NewText: null,
      loadAnswer: false,
      chatMessages: [],
      default_questions: [
        { text: "Generate an amendments clause.", displayed: false },
        {
          text: "Generate a clause to define the important terms of this agreement.",
          displayed: false,
        },
      ],
    };
  },

  methods: {
    SummaryTimeout() {
      var me = this;
      setTimeout(function () {
        if (!me.$route.params.id) return;
        
        LegalWriter.ViewLegalWriter(me.$route.params.id).then((response) => {
          if (response.data.data.output_summarization == null) {
            me.SummaryTimeout();
          } else {
            me.summaryLoading = false;
            me.summary = response.data.data.output_summarization;
          }
        });
      }, 10000);
    },

    generateNewSummary() {
      if (!this.summaryLoading) {
        this.$bvModal.show("suggestion-summary");
        this.summaryLoading = true;
        LegalWriter.SummarizeLetter(this.$route.params.id)
          .then((response) => {
            var me = this;
            Auth.getMe().then((res) => {
              localStorage.setItem("userInfo", JSON.stringify(res.data.data));
            });
            setTimeout(function () {
              LegalWriter.ViewLegalWriter(me.$route.params.id).then(
                (response) => {
                  if (response.data.data.output_summarization == null) {
                    me.SummaryTimeout();
                  } else {
                    me.summaryLoading = false;
                    me.summary =
                      response.data.data.output_summarization.replace(
                        /\n/g,
                        "<br>"
                      );
                  }
                }
              );
            }, 50000);
          })

          .catch((error) => {
            console.log(error);
          });
      }
    },

    openSummary() {
      this.$bvModal.show("suggestion-summary");
    },

    saveChanges({ content }) {
      LegalWriter.SaveChanges(
        this.$route.params.id,
        { output: content },
        "LWriter"
      )
        .then((response) => {
          this.$toast.success("Saved");
        })
        .catch((error) => {
          console.log(error);
        });
    },

    loadResult(data) {
      this.letter = data.output
        .replaceAll("\n", "<br>")
        .replaceAll(/\*\*(.*?)\*\*/g, "<b>$1</b>")
        .replace(/\[([^\]]+)\]/g, (match, text) => {
          const existingTags1 = this.letter.match(
            new RegExp(
              `\\<span class="ql-bg-yellow"\\>(?!\\<span class="ql-bg-yellow"\\>\\[${text}\\]<\\/span>)`,
              "g"
            )
          );
          if (existingTags1 && existingTags1.length > 0) {
            return match; // If similar tag already exists, return the match as is
          } else {
            return `<span class="ql-bg-yellow">\[${text}\]<\/span>`; // Add the tag
          }
        })
        .replace(/~~(.*?)~~/g, '<p class="ql-indent-1">$1</p>');

      this.changedLetter = this.letter;
      this.compose_type = data.compose_type;
      this.content = data.info;

      const LODcat = [
        "Outstanding Payment",
        "Property Damage Claim",
        "Insurance Claim",
      ].includes(this.$route.query.category);
      var info = {};
      if (LODcat) {
        if (this.$route.query.category == "Outstanding Payment") {
          info["Outstanding Amount"] = data.info.out_amount;
        } else if (
          ["Property Damage Claim", "Insurance Claim"].includes(
            this.$route.query.category
          )
        ) {
          info["Claim Amount"] = data.info.out_amount;
        }

        info = {
          ...info,
          Agreement: data.info.agreement,
          "Contract Details": data.info.contract_details,
          Deadline: data.info.deadline,
          "Additional Details": data.info.additional_info,
          "Damage Caused": data.info.damage_cause,
          "Claim Number": data.info.claim_number,
          "Policy Number": data.info.policy_number,
          "Coverage Explanation": data.info.coverage_exp,
        };
      } else {
        info = {
          Creative: data.info.creative_field,
          Technology: data.info.technology,
          "Business Information": data.info.business_info,
          "Partnership Description": data.info.partnership_description,
          "Description of Goods": data.info.ipa_description,
          "Business Description": data.info.business_description,
          "Constructor Description": data.info.constructor_description,
          Description: data.info.job_description,
          "Board Description": data.info.board_description,
          "Product Type": data.info.product_type,
          "Project Description": data.info.project_description,
          "Product Description": data.info.product_description,
          "Option Duration": data.info.option_duration,
          "Option Fee": data.info.option_fee,
          "Movie Name": data.info.movie_name,
          "Loan Purpose": data.info.loan_purpose,
          Item: data.info.item,
          Quantity: data.info.quantity,
          Condition: data.info.condition,
          "Movie Budget": data.info.movie_budget,
          Price: data.info.price,
          "From - To": data.info.from
            ? data.info.from + " - " + data.info.to
            : null,
          "Additional Information": data.info.additional_info,
        };
      }
      this.letter_details = Object.fromEntries(
        Object.entries(info).filter(
          ([key, value]) => value !== null && value !== "undefined"
        )
      );

      this.summary = data.output_summarization
        ? data.output_summarization.replace(/\n/g, "<br>")
        : data.output_summarization;

      const OutMessage = {
        class_1: "chat__outgoing",
        class_2: "chat__bubble__wrapper-outgoing",
        class_3: "chat__bubble-outgoing animated-background-outgoing",
        new: false,
      };
      const InMessage = {
        class_1: "chat__incoming",
        class_2: "chat__bubble__wrapper-incoming",
        class_3: "chat__bubble-incoming animated-background-incoming",
        new: false,
      };
      data.questions.forEach((element) => {
        console.log(element);
          if ((element.answer?.length ?? false) != 0) {
            this.default_questions.splice(
              this.default_questions[
                this.default_questions.findIndex((x) => x === element.question)
              ],
              1
            );

            OutMessage.text = element.question;
            this.chatMessages.push({...OutMessage});

            try {
              JSON.parse(element.answer).forEach((answer) => {
                InMessage.text = answer;
                this.chatMessages.push({...InMessage});
              });
            } catch (error) {
              InMessage.text = element.answer
              this.chatMessages.push({...InMessage});
            }
          }
        });

      if (
          DATA.questions.length != 0 &&
          DATA.questions[DATA.questions.length - 1].answer == null
        ) {
          this.loadAnswer = true;
          this.calltime("none");

          OutMessage.text = DATA.questions[
            DATA.questions.length - 1
          ].question;
          this.chatMessages.push(OutMessage);
        }

      if (this.chatMessages.length == 0) {
        InMessage.text = "You may ask question about the case here🔎";
        this.chatMessages.push(InMessage);
      }
    },

    chooseMessage(message, index) {
      this.loadAnswer = true;
      this.chatMessages.push({
        text: message.text,
        class_1: "chat__outgoing",
        class_2: "chat__bubble__wrapper-outgoing",
        class_3: "chat__bubble-outgoing animated-background-outgoing",
        new: false,
      });

      // Update the displayed property of the chosen question
      this.default_questions[index].displayed = true;
      // // Find the next question that hasn't been displayed
      // const nextQuestion = this.default_questions
      //   .find((question) => !question.displayed);

      var obj = {
        question: message.text,
        mask: "true",
      };

      // console.log("Passed into API", obj);
      // return;
      LegalWriter.sendQuestion("lod", this.$route.params.id, obj)
        .then(() => {
          this.default_questions.splice(
            this.default_questions.findIndex((x) => x.text === message.text),
            1
          );
          this.calltime("default");
        })
        .catch((error) => {
          console.log(error);
          this.loadAnswer = false;
        });

      var div = document.getElementById("messages");
      div.scrollTop = div.scrollHeight;

      this.$nextTick(() => {
        var messageDisplay = this.$refs.messages1;
        messageDisplay.scrollTop = messageDisplay.scrollHeight;
      });
    },

    sendNewMessage() {
      if (this.NewText.length != 0) {
        this.loadAnswer = true;
        this.chatMessages.push({
          text: this.NewText,
          class_1: "chat__outgoing",
          class_2: "chat__bubble__wrapper-outgoing",
          class_3: "chat__bubble-outgoing animated-background-outgoing",
          new: false,
        });

        var obj = {
          question: this.NewText,
          mask: "true",
        };
        this.NewText = "";

        // console.log("Passed into API", obj);
        // return;
        LegalWriter.sendQuestion("lod", this.$route.params.id, obj)
          .then(() => {
            Auth.getMe().then((res) => {
              localStorage.setItem("userInfo", JSON.stringify(res.data.data));
              const credits = res.data.data.nexlaw_credits;
              this.contract_questions_full = credits.full.contract_questions;
              this.contract_questions_remain =
                credits.full.contract_questions -
                credits.used.contract_questions;
            });
            this.calltime("none");
          })
          .catch((error) => {
            console.log(error);
            this.loadAnswer = false;
          });

        var div = document.getElementById("messages");
        div.scrollTop = div.scrollHeight;

        this.$nextTick(() => {
          var messageDisplay = this.$refs.messages1;
          messageDisplay.scrollTop = messageDisplay.scrollHeight;
        });
      }
    },

    calltime(data) {
      var me = this;
      setTimeout(function () {
        LegalWriter.ViewLegalWriter(me.$route.params.id)
          .then((response) => {
            const questions = response.data.data.questions;
            const last_q = questions[questions.length - 1];
            if (last_q.answer != null) {
              try {
                JSON.parse(last_q.answer).forEach((answer) => {
                  me.chatMessages.push({
                    text: answer,
                    class_1: "chat__incoming",
                    class_2: "chat__bubble__wrapper-incoming",
                    class_3: "chat__bubble-incoming animated-background-incoming",
                    new: true,
                  });
                });
              } catch (error) {
                me.chatMessages.push({
                  text: last_q.answer,
                  class_1: "chat__incoming",
                  class_2: "chat__bubble__wrapper-incoming",
                  class_3: "chat__bubble-incoming animated-background-incoming",
                  new: true,
                });
              }
              me.loadAnswer = false;
            } else {
              me.calltime(data);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }, 5000);
    },

    scrollToBottom() {
      // Scroll to the bottom of the chat container
      this.$nextTick(() => {
        const chatContainer = this.$refs.messages1;
        chatContainer.scrollTop = chatContainer.scrollHeight;
      });
    },

    // editor
    initEditor({ editor, content }) {
      this.editor = editor;
      this.changedLetter = this.letter;
    },
    updateEditor({ content }) {
      this.changedLetter = content;
    },
  },

  created() {
    localStorage.removeItem("lod_first_data");

    LegalWriter.ViewLegalWriter(this.$route.params.id)
      .then((response) => {
        this.editorLoading = false;
        if (response.data.data.completedPerCentage < 100) {
          this.duration = response.data.data.completedPerCentage;

          this.interval = setInterval(() => {
            LegalWriter.ViewLegalWriter(this.$route.params.id)
              .then((res) => {
                if (res.data.data.completedPerCentage >= 100) {
                  clearInterval(this.interval);
                  location.reload();
                }
                this.duration = res.data.data.completedPerCentage;
              }
            );
          }, 10000);
        } else {
          this.duration = 100;
          this.loadResult(response.data.data);
        }
      })

      .catch((error) => {
        console.log(error);
      });
  },

  destroyed() {
    clearInterval(this.interval);
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style scoped>
.export-icon {
  color: #094890;
  font-size: 2rem;
}

.blue-icon {
  color: #094890;
  font-size: 1.5rem;
  cursor: pointer;
}

/* .yellow-background {
  background: rgba(250, 250, 134, 0.93);
} */

.bold,
.paragraph {
  padding: 0.5rem;
  border-radius: 10px;
}

.warning-icon {
  display: inline;
  color: red;
  font-size: 1.2rem;
}

.popover {
  max-width: 500px !important;
  background: #094890;
}

.popover_container h5 {
  color: white;
}

.card_height {
  max-height: 600px;
  overflow: scroll;
}

.ql-container {
  height: 600px;
}

.icon-export-blue {
  color: #094890;
  font-size: 2rem;
}

#generateSummaryButton {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

@media only screen and (max-width: 375px) {
  #generateSummaryButton {
    padding-top: 2px !important;
    padding-bottom: 50px !important;
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  #saveChangesButton {
    padding-top: 5px !important;
    padding-bottom: 30px !important;
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
}

.spinner {
  color: #f08000 !important;
}

.spinner-border {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-vertical-align: -0.15em;
  --bs-spinner-border-width: 0.2em;
}

/* .highlight {
  background-color: yellow;
} */

.info-header[aria-expanded="true"] .arrow-icon {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.info-header[aria-expanded="false"] .arrow-icon {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.arrow-icon {
  display: none;
  display: block;
  -webkit-transition: transform 0.5s ease;
  -o-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
  color: black;
}

/* chatBot Css */
.animated-background-outgoing {
  background: linear-gradient(
    45deg,
    #012741,
    #004877,
    #006bb3,
    #0866a5,
    #0976be
  );
  background-size: 200% 100%;
  animation: gradientAnimation 3s linear infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }

  25% {
    background-position: 100% 0%;
  }

  50% {
    background-position: 100% 100%;
  }

  75% {
    background-position: 0% 100%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.animated-background-incoming {
  background: linear-gradient(
    45deg,
    #cccaca,
    #bcbebe,
    #d4cece,
    #d3d4d4,
    #f1f1f1
  );
  background-size: 200% 100%;
  animation: gradientAnimation 3s linear infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }

  25% {
    background-position: 100% 0%;
  }

  50% {
    background-position: 100% 100%;
  }

  75% {
    background-position: 0% 100%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.card_bot_question {
  height: 800px;
  overflow-y: scroll;
}

.chat__box {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  height: 100%;
  overflow-y: scroll;
}

.chat__incoming {
  margin-right: auto;
  margin-top: 0.5rem;
}

.chat__bubble__wrapper-incoming {
  margin-right: auto;
  margin-bottom: 1rem;
}

.chat__bubble-incoming {
  padding: 0.3rem 1rem;
  background-color: #f1f1f1;
  border-radius: 30px;
  border-bottom-left-radius: 0px;
  margin-right: 3rem;
  margin-left: 0.5rem;
}

.chat__bubble-incoming span {
  color: #333333;
}

.chat__outgoing {
  margin-left: auto;
  margin-top: 0.5rem;
}

.chat__bubble__wrapper-outgoing {
  margin-left: auto;
  margin-bottom: 1rem;
}

.chat__bubble-outgoing {
  padding: 0.3rem 1rem;
  background-color: #86d4f8;
  border-radius: 30px;
  border-bottom-right-radius: 0px;
  margin-left: 3rem;
}

.chat__bubble-outgoing span {
  color: #fff;
}

/* chat suggestions and input */
.chat__suggestion {
  display: flex;
  border: 2px solid #dddddd;
  border-radius: 20px;
  padding-left: 10px;
  height: auto;
  margin-bottom: 0.5rem;
}

.chat__suggestion:hover {
  background-color: #f1f1f1;
  transition: 300ms ease-out;
}

.chat__suggestion span {
  text-align: left;
  padding-top: 0.2rem;
}

.chat__input {
  display: flex;
  border: 2px solid #0058a2;
  border-radius: 20px;
  padding-left: 10px;
}

.chat__input input {
  border: none;
  border-radius: 20px;
  height: 40px;
  width: 100%;
  box-shadow: none;
  outline: none;
}

.chat__input .btn-send {
  padding: 0;
  margin: 0;
  height: 38px;
}

.chat__input .btn-send span {
  color: #0058a2;
  font-size: 40px;
  transition: 300ms ease-out;
}

.chat__input .btn-send:hover span {
  color: #004278;
}
</style>

<style>
@media screen and (min-width: 1553px) {
  .ql-container {
    height: 650px !important;
  }
}
</style>